<template>
  <b-card title="Courses">
    <b-tabs>
  
     
     <b-tab :active="currentRouteName=='add-course'"  @click="$router.push('/add-course')"  >
      
        <template #title>
          <feather-icon icon="PlusIcon" />
          <!-- <a  href="/add-course">New Courses</a> -->
          
           <span>New Courses</span>
        </template>

      </b-tab>
      <b-tab  :active="currentRouteName=='classical-courses'"   @click="$router.push('/classical-courses')" >
        <template #title>
          <feather-icon icon="HomeIcon" />
          <!-- <a  href="/classical-courses"> Classical Courses</a> -->
          
           <span> Classical Courses</span>
        </template>
       
        <!-- <router-link :to="{ name: 'classical-courses' }">Tab 1</router-link> -->
        <!-- <ClassicalCourses ref="classic"></ClassicalCourses> -->
      </b-tab>
      <b-tab :active="currentRouteName=='online-courses'" @click="$router.push('/online-courses')"  >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <!-- <a  href="/online-courses">Online Courses</a> -->
          <span>Online Courses</span>
        </template>
       
      </b-tab>
      
       <b-tab :active="currentRouteName=='generate-course'" @click="$router.push('/generate-course')" >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <!-- <a  href="/generate-course">Generate Online Courses</a> -->
          <span>Generate Online Courses</span>
        </template>
<!--  -->

      </b-tab>
     
     
     
    </b-tabs>
      <router-view v-slot="{ Component, route }" href="https://board.meemdigital.work/?controller=BoardViewController&action=show&project_id=6" >
      <AddCourse v-if="route.name === 'index-course ' ||route.name === 'add-course' "  />
      <router-view  v-else href="https://board.meemdigital.work/?controller=BoardViewController&action=show&project_id=6" >
          <component :is="Component" />
        </router-view>
  </router-view>
 
  </b-card>
</template>

<script>

import { BTabs, BTab, BCardText,BCard,BLink } from 'bootstrap-vue'
import ClassicalCourses from './ClassicalCourses.vue';

import OnlineCourses from './OnlineCourses.vue';
import AddCourse from './AddCourse.vue';
import GenerateOnlineCourse from './generateOnlineCourse.vue';

import { ref, onMounted } from "@vue/composition-api";
import { useRouter } from "@core/utils/utils";
// import {RouterLink} from 'vue-router'
export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
    ClassicalCourses,
    AddCourse,
    OnlineCourses,
    GenerateOnlineCourse,
    BLink
},
// props:{ ...RouterLink.props },
   setup() {
    // console.log("RouterLink",RouterLink)
    const online=ref()
    const { route } = useRouter();
    const classic=ref()
    const tabIndex=ref(0)
   
    const getData=()=>{
  
      classic.value.refetchData()
            online.value.refetchData()
// onMounted(() => {
//   tabIndex.value = tabs.value.findIndex(tab => tab === route.value.hash)
//   })
    }
    
   return{
    getData,
    classic,
    tabIndex,
    online

   }
   
   },
    computed: {
    currentRouteName() {
        return this.$route.name;
    }}}
</script>
